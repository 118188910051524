import $ from 'jquery';

$(document).on('turbolinks:load', function() {

  $('.edit_playlist_form').on('ajax:complete', function(e) {
    if (e.detail[0] && e.detail[0].status === 200) {
      alert("Your playlist was updated!");
    } else {
      alert("Something didn't work - talk to Nathan.");
    }
  });

  $('.new_playlist_form').on('ajax:complete', function(e) {
    if (e.detail[0] && e.detail[0].status === 200) {
      alert("Your playlist was created!");
      window.location = JSON.parse(e.detail[0].responseText).playlist_url
    } else {
      alert("Something didn't work - talk to Nathan.");
    }
  });

  $('.edit-episode-form').on('ajax:complete', function(e) {
    if (e.detail[0] && e.detail[0].status === 200) {
      alert("The episode was updated!");
      location.reload()
    } else {
      alert("Something didn't work - talk to Nathan.");
    }
  });

  $('.add-episode').on('click', function() {
    var playlistId = $('.edit_playlist_form').data('playlist-id');
    var episodeId = prompt('Enter an episode id:');
    if (episodeId) {
      $.ajax({
        url: `/admin/playlists/${playlistId}/episodes/${episodeId}`,
        method: 'POST',
        data: {authenticity_token: $('[name="csrf-token"]')[0].content},
        success: function(data) {
          location.reload()
        },
        error: function(data) {
          alert('Something went wrong! Email nbashaw@gmail.com and say you hit a bug.');
        }
      });
    }
  })

});