import $ from 'jquery';

// jQuery plugin to prevent double submission of forms
$.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
    }
  });

  // Keep chainability
  return this;
};

$(document).on("turbolinks:load", function() {

  window.TubaSearch = {}

  // Handle the search input
  TubaSearch.lastQuery = '';
  TubaSearch.typingTimeout = null;
  $("#podcast-search").on('keyup', function(data) {
    window.clearTimeout(TubaSearch.typingTimeout);
    var query = $('#podcast-search').val();
    if (TubaSearch.lastQuery != query) {
      $('.podcast-search-results').addClass('loading');
      $('.podcast-search-results').addClass('show');
      TubaSearch.typingTimeout = setTimeout(function(){
        if (query.length > 3 ) {
          searchPodcasts(query);
        } else {
          $('.podcast-search-results').removeClass('show');
        }
        TubaSearch.lastQuery = query;
      }, 300);
    }
  });

  // Search via the iTunes API
  var searchPodcasts = function(query) {
    $.ajax({
      method: 'GET',
      url: '/admin/podcasts/search',
      data: {q: query},
      success: function(data) {
        TubaSearch.results = data.results;
        renderPodcastResults(data.results);
      },
      dataType: 'json'
    });
  }

  // Render search results
  var renderPodcastResults = function(results) {
    $('.podcast-search-results').html('');
    $('.podcast-search-results').removeClass('loading');
    for (let result in results) {
      var pod = results[result];
      var html = '<li data-index="'+result+'"><img src="'+pod.artworkUrl60+'">';
      html += '<span class="pod-name">'+pod.trackName+'</span>';
      html += '<span class="pod-maker">'+pod.artistName+'</span></li>';
      $('.podcast-search-results').append(html);
      selectPodcast(0);
    }
    if (results.length == 0) {
      var html = '<p class="no-results">There\'s nothing in Apple Podcasts with that name :(</p>'
      $('.podcast-search-results').append(html);
    }
  }

  // Set selection
  TubaSearch.selectedPodcastIndex = 0;
  var selectPodcast = function(index) {
    TubaSearch.selectedPodcastIndex = index;
    var chosenPodcast = TubaSearch.results[index];
    $('#podcast-info').val(JSON.stringify(chosenPodcast));
    $('.podcast-search-results li').removeClass('selected');
    $($('.podcast-search-results li')[index]).addClass('selected');
  }

  // Change Selection via Keyboard
  $(window).on('keyup', function(e) {
    if (e.keyCode == 38 && TubaSearch.selectedPodcastIndex > 0) {
      selectPodcast(TubaSearch.selectedPodcastIndex - 1);
    } else if (e.keyCode == 40) {
      selectPodcast(TubaSearch.selectedPodcastIndex + 1);
    } else if (e.keyCode == 13) {
      e.preventDefault();
      chooseThisPodcast();
    }
  });

  // Change Selection via Hover
  $('body').on('mouseover', '.podcast-search-results li', function(e){
    var index = $(this).data('index');
    selectPodcast(index);
  });

  // Select via click
  $('body').on('click', '.podcast-search-results li', function(e) {
    chooseThisPodcast();
  });

  // Execute selection
  var chooseThisPodcast = function() {
    $('#home-form').submit();
  }

  // Prevent multi-submit
  $('#home-form').preventDoubleSubmission();

});