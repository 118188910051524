import $ from 'jquery';

function seconds_to_hhmmss(t) {
    if (isNaN(t)) return '-:--';
    var h = parseInt(t / 3600);
    var m = parseInt(t / 60) % 60;
    var s = parseInt(t % 60);
    return (h ? h + ':' : '') + (h ? ('0' + m).slice(-2) : m) + ':' + ('0' + s).slice(-2);
}

$(document).on('turbolinks:load', function() {
  
  let player = $('#audio-player')[0];
  let playPauseBtn = $('#play-pause')[0];
  let isPlaying = false;
  let duration = null;
  let draggingProgressBar = false;
  const $progressBar = $('#progressbar');
  const $endTime = $('#end-time');
  const $startTime = $('#start-time');

  // Init progress bar
  $(player).on('loadedmetadata', function() {
    duration = Math.round(player.duration);
    $progressBar.attr('max', player.duration);
    $progressBar.val(0);
    $endTime.text(seconds_to_hhmmss(player.duration));
  });

  // Handle play/pause button
  $('#play-pause').on('click', function(){
    if (isPlaying === false) {
      player.play();
    } else {
      player.pause();
    }
  });

  // Handle "play"
  $(player).on('play', function() {
    $(playPauseBtn).addClass('playing');
    isPlaying = true;
  });

  // Handle "pause"
  $(player).on('pause', function() {
    $(playPauseBtn).removeClass('playing');
    isPlaying = false;
  })

  // Update progress bar
  $(player).on('timeupdate', function(e) {
    if (! isFinite(e.target.duration)) return;
    if (draggingProgressBar) return;
    const totalTime = Math.round(e.target.duration);
    const currentTime = Math.round(e.target.currentTime);
    $progressBar.val(currentTime);
    $startTime[0].innerHTML = (seconds_to_hhmmss(currentTime));
    $endTime[0].innerHTML = (seconds_to_hhmmss(totalTime - currentTime));
  });

  // Update progressbar during drag
  $progressBar.on('input', function() {
    $startTime[0].innerHTML = (seconds_to_hhmmss($progressBar.val()));
    $endTime[0].innerHTML = (seconds_to_hhmmss(duration - $progressBar.val()));
  });

  // Track dragging state
  $progressBar.on('mousedown', function() {
    draggingProgressBar = true;
  });
  $progressBar.on('mouseup', function() {
    draggingProgressBar = false;
  });

  // Skip to time
  $progressBar.on('change', function() {
    player.currentTime = $progressBar.val();
    player.play();
  });

  // Skip back 30s
  $('#skip-back').on('click', function(){
    player.currentTime -= 30;
  });

  // Skip forward 30s
  $('#skip-forward').on('click', function() {
    player.currentTime += 30;
  });

  // Set playback speed
  const speeds = [0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0, 3.2, 3.4, 3.6];
  let currentSpeed = 1;
  $('#increase-playback-speed').on('click', function() {
    if (currentSpeed < (speeds.length - 1)) {
      currentSpeed += 1;
    }
    player.playbackRate = speeds[currentSpeed];
    $('#playback-speed-label').text(speeds[currentSpeed] + '×');
  })
  $('#decrease-playback-speed').on('click', function() {
    if (currentSpeed > 0) {
      currentSpeed -= 1;
    }
    player.playbackRate = speeds[currentSpeed];
    $('#playback-speed-label').text(speeds[currentSpeed] + '×');
  });

});