import $ from 'jquery';
const _ = require('lodash');

$(document).on('turbolinks:load', function() {

  // Perform vote HTTP request
  const performVote = function($el, url, method) {
    const currentVoteCount = Number($el.find('.vote-count').text());
    if (method === 'POST') {
      $el.find('.vote-count').text(currentVoteCount + 1);
    } else if (method === 'DELETE') {
      $el.find('.vote-count').text(currentVoteCount - 1);
    }
    $el.toggleClass('voted');
    $.ajax({
      url,
      method,
      data: {authenticity_token: $('[name="csrf-token"]')[0].content},
      success: function(data) {
        $el.find('.vote-count').text(data.votes); // set it anyway in case new votes came in since initial page load
        updateVoteStore($el, method);
      },
      error: function(data) {
        alert('Something went wrong! Email nbashaw@gmail.com and say you hit a bug trying to vote for topics.');
      }
    });
  }

  // Init localstore
  if (typeof localStorage['topicVotes'] === 'undefined') {
    localStorage['topicVotes'] = JSON.stringify([]);
  } else {
    const votes = JSON.parse(localStorage['topicVotes']);
    _.each(votes, function(value) {
      $(`.vote-button[data-id=${value}]`).addClass('voted');
    })
  }

  // Update votes localstore
  const updateVoteStore = function($el, method) {
    const id = Number($el.data('id'));
    let upvotes = JSON.parse(localStorage['topicVotes']);
    if (method === 'POST') {
      upvotes.push(id);
    } else if (method === 'DELETE') {
      upvotes = _.without(upvotes, id);
      console.log(upvotes);
    }
    localStorage['topicVotes'] = JSON.stringify(upvotes);
  }

  // Handle vote click
  $('.vote-button').on('click', function(e) {
    const $el = $(e.currentTarget);
    if (!$el.hasClass('voted')) {
      performVote($el, $el.data('upvote-url'), 'POST');
    } else if ($el.hasClass('voted')) {
      performVote($el, $el.data('cancelvote-url'), 'DELETE');
    }
  });

});

// TODOS
// - figure out require vs. import
// - ask you to sign in with google after you vote